import * as Sentry from "@sentry/vue";
import { defineNuxtPlugin } from "nuxt/app";

export default defineNuxtPlugin((nuxtApp) => {
  try {
    const config = useRuntimeConfig();
    const sentryDsn = config.public?.sentry?.dsn || "https://5cb15b3f2ee9e8bdcb7f71e03b2b53b5@o4509006573207552.ingest.us.sentry.io/4509006581530624";
    const environment = config.public?.sentry?.environment || process.env.NODE_ENV || 'development';
    
    // Get the app version from the version plugin or fallback to config
    const { $appVersion } = useNuxtApp();
    const appVersion = `njgerner-com@${$appVersion || '1.0.0'}`;
    
    // Initialize Sentry with Vue integration
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: sentryDsn,
      environment: environment,
      
      // We recommend adjusting this in production
      tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.2 : 1.0,
      
      // Enable debug output in development
      debug: process.env.NODE_ENV !== 'production',
      
      // Add release information using semantic versioning
      release: (config.public?.sentryRelease as string) || appVersion,
      
      // Configure error handlers
      attachProps: true
    });
    
    console.log('Sentry client initialized successfully');
    console.log('Sentry release:', config.public?.sentryRelease || appVersion);
    
    // Provide Sentry to the rest of the application
    return {
      provide: {
        sentry: Sentry
      }
    };
  } catch (error) {
    console.error('Failed to initialize Sentry client:', error);
    return {};
  }
}) 