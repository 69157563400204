import { useAuth0 } from '@auth0/auth0-vue';
import type { Auth0Context } from '~/types/auth';

export function useAuth(): Auth0Context {
  // Create default values for server-side rendering
  const defaultAuth: Auth0Context = {
    user: ref(null),
    isAuthenticated: ref(false),
    isLoading: ref(false),
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    getAccessTokenSilently: () => Promise.resolve(''),
    fetchWithAuth: () => Promise.resolve(null)
  };
  
  // Check if we're on the client side
  if (process.server) {
    return defaultAuth;
  }
  
  // Only execute Auth0 code on the client side
  try {
    const nuxtApp = useNuxtApp();
    // Get Auth0 instance from plugin
    const auth = useAuth0();
    
    // Check if auth is undefined
    if (!auth) {
      console.error('Auth0 initialization failed: auth object is undefined');
      return defaultAuth;
    }
    
    // Wrap auth state reactive objects to make them more resilient to race conditions
    const safeUser = computed(() => {
      try {
        return auth.user.value || null;
      } catch (error) {
        console.error('Error accessing auth user value:', error);
        return null;
      }
    });

    const safeIsAuthenticated = computed(() => {
      try {
        return !!auth.isAuthenticated.value;
      } catch (error) {
        console.error('Error accessing auth isAuthenticated value:', error);
        return false;
      }
    });

    const safeIsLoading = computed(() => {
      try {
        return !!auth.isLoading.value;
      } catch (error) {
        console.error('Error accessing auth isLoading value:', error);
        return false;
      }
    });
    
    // Function to get the access token and add it to API requests
    const getAuthHeaders = async () => {
      if (!auth.isAuthenticated.value) {
        console.warn('User is not authenticated, cannot get token');
        return {};
      }
      
      try {
        console.log('Requesting access token...');
        const token = await auth.getAccessTokenSilently({
          authorizationParams: {
            audience: useRuntimeConfig().public.auth0Audience,
          }
        });
        console.log('Token received, length:', token?.length || 0);
        return {
          Authorization: `Bearer ${token}`
        };
      } catch (error) {
        console.error('Error getting access token:', error);
        return {};
      }
    };
    
    // Create a version of useFetch that automatically includes auth headers
    const fetchWithAuth = async (url: string, options: any = {}) => {
      console.log(`==== FETCH WITH AUTH for ${url} ====`);
      console.log('Request options:', JSON.stringify(options, (key, value) => 
        key === 'body' ? '[BODY CONTENT]' : value, 2));
      
      const headers = await getAuthHeaders();
      console.log('Auth headers obtained:', Object.keys(headers).length > 0 ? 'Yes' : 'No');
      
      // Ensure Content-Type is set for requests with body data
      if (options.body && !options.headers?.['Content-Type']) {
        if (!options.headers) options.headers = {};
        options.headers['Content-Type'] = 'application/json';
        console.log('Added Content-Type header for request with body');
      }
      
      const mergedOptions = {
        ...options,
        headers: {
          ...options.headers,
          ...headers
        }
      };
      console.log('Merged options:', JSON.stringify(mergedOptions, (key, value) => 
        key === 'body' ? '[BODY CONTENT]' : value, 2));
      
      try {
        // Check if component is already mounted (client-side) - use $fetch instead of useFetch
        const isHydrated = useNuxtApp().isHydrating === false;
        
        if (isHydrated) {
          console.log('Component already mounted, using $fetch instead of useFetch');
          const data = await $fetch(url, mergedOptions);
          console.log('Response received from $fetch');
          return { data: ref(data), error: ref(null) };
        } else {
          const response = await useFetch(url, mergedOptions);
          console.log('Response received:', response.data.value ? 'Has data' : 'No data', 
            response.error.value ? 'Has error' : 'No error');
          
          if (response.error.value) {
            console.error('Error from API:', response.error.value);
          }
          
          return response;
        }
      } catch (error) {
        console.error(`Error in fetchWithAuth for ${url}:`, error);
        throw error;
      } finally {
        console.log(`==== FETCH WITH AUTH for ${url} COMPLETED ====`);
      }
    };
    
    // Enhanced token retrieval function with better error handling
    const getAccessTokenSilently = async () => {
      if (!auth.isAuthenticated.value) {
        console.warn('User is not authenticated, cannot get token');
        return '';
      }
      
      try {
        console.log('Requesting access token with audience:', useRuntimeConfig().public.auth0Audience);
        const token = await auth.getAccessTokenSilently({
          authorizationParams: {
            audience: useRuntimeConfig().public.auth0Audience,
          }
        });
        return token;
      } catch (error) {
        console.error('Error getting access token:', error);
        throw error;
      }
    };
    
    return {
      user: safeUser,
      isAuthenticated: safeIsAuthenticated,
      isLoading: safeIsLoading,
      login: (options?: any) => auth.loginWithRedirect(options),
      logout: () => auth.logout({ logoutParams: { returnTo: window.location.origin } }),
      getAccessTokenSilently,
      fetchWithAuth
    };
  } catch (error) {
    console.error('Error initializing Auth0:', error);
    return defaultAuth;
  }
} 