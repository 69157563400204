/**
 * This plugin ensures color mode hydration happens correctly
 * by forcing client-side color mode detection before the app renders
 */
import { defineNuxtPlugin, useColorMode } from '#imports';

// This plugin fixes an issue with color-mode library where 
// cookie-based dark mode isn't properly applied on initial load
// The fix ensures that color mode is consistently applied
export default defineNuxtPlugin(() => {
  // Making this client-side only
  if (import.meta.client) {
    const colorMode = useColorMode();
    
    // Force apply the color mode based on user preference
    if (colorMode.preference) {
      const html = document.documentElement;
      html.classList.remove('light', 'dark', 'sepia');
      html.classList.add(colorMode.value);
    }
  }
}); 