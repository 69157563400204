/**
 * This plugin silences the timer-related warnings that occur during navigation.
 * These warnings don't affect functionality and are primarily due to how Nuxt
 * handles multiple navigations and cancellations.
 */
export default defineNuxtPlugin(() => {
  // Only in development, override the console.warn to filter out timer warnings
  if (process.dev) {
    const originalWarn = console.warn;
    console.warn = function(...args) {
      // Filter out timer-related warnings
      if (args[0] && typeof args[0] === 'string' && 
          (args[0].includes('Timer') || args[0].includes('page:loading'))) {
        return;
      }
      originalWarn.apply(console, args);
    };
    
    console.log('Silenced navigation timer warnings for development');
  }
}); 