import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/vercel/path0/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import dom_safety_client_z9jxoLiE8EILUyw0BH2kGy1HWgOdw3DitLSOgFWwTtE from "/vercel/path0/plugins/dom-safety.client.ts";
import vue3_easymde_client_ugM8vuRQI7VZ8D_eiL1fWN5avAWu3BkUae9vqJXIU5o from "/vercel/path0/plugins/vue3-easymde.client.ts";
import auth0_client_5gFjAQju_YanoEYjppZAhnGf1mje1ZF5Ww0YdsAdELU from "/vercel/path0/plugins/auth0.client.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/vercel/path0/plugins/sentry.client.ts";
import auth_protection_client__uNHF4zHSFuSMunWt6LoshAcH4FzJoa4bvWDtF1NeOs from "/vercel/path0/plugins/auth-protection.client.ts";
import color_mode_fix_client_e58F_IYvZXqPevmKvRqHXP_GFwIpRz4OHxhlouV_Tgk from "/vercel/path0/plugins/color-mode-fix.client.ts";
import icon_fallback_client_UyOmQtotJ_tErk2GzOtJWqNz5LGvW83hu6SGUld9P1g from "/vercel/path0/plugins/icon-fallback.client.ts";
import markdown_it_HDu_aSefzW5TUSp2aH3mkoYeoaY_g7kJGcIc0JozbSI from "/vercel/path0/plugins/markdown-it.ts";
import silence_timer_warnings_client_IW2wmp0va45zxn_wEQCHZrAGg_x7eYaSn_KvazyltLI from "/vercel/path0/plugins/silence-timer-warnings.client.ts";
import version_pIi5SMUzzX_hQ8tEo_kZs1DmYamlHb5Iq96pXhZPGk0 from "/vercel/path0/plugins/version.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  dom_safety_client_z9jxoLiE8EILUyw0BH2kGy1HWgOdw3DitLSOgFWwTtE,
  vue3_easymde_client_ugM8vuRQI7VZ8D_eiL1fWN5avAWu3BkUae9vqJXIU5o,
  auth0_client_5gFjAQju_YanoEYjppZAhnGf1mje1ZF5Ww0YdsAdELU,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  auth_protection_client__uNHF4zHSFuSMunWt6LoshAcH4FzJoa4bvWDtF1NeOs,
  color_mode_fix_client_e58F_IYvZXqPevmKvRqHXP_GFwIpRz4OHxhlouV_Tgk,
  icon_fallback_client_UyOmQtotJ_tErk2GzOtJWqNz5LGvW83hu6SGUld9P1g,
  markdown_it_HDu_aSefzW5TUSp2aH3mkoYeoaY_g7kJGcIc0JozbSI,
  silence_timer_warnings_client_IW2wmp0va45zxn_wEQCHZrAGg_x7eYaSn_KvazyltLI,
  version_pIi5SMUzzX_hQ8tEo_kZs1DmYamlHb5Iq96pXhZPGk0
]