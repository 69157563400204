/**
 * Global authentication middleware for protecting routes
 */
export default defineNuxtRouteMiddleware((to) => {
  // List of routes that require authentication
  const protectedRoutes = [
    '/blog/new',
    '/profile'
  ];
  
  // Check if the current route is protected
  const isProtectedRoute = protectedRoutes.some(route => to.path.startsWith(route));
  
  if (isProtectedRoute) {
    // Skip auth checks during server-side rendering
    // Auth will be handled by the component's client-side code
    if (process.server) {
      return;
    }
    
    // Get the auth state
    const { isAuthenticated, isLoading } = useAuth();
    
    // During initial load, we won't redirect yet since isLoading might be true
    // The component will handle showing loading state and login button
    if (isLoading.value) {
      console.log('Auth still loading, allowing navigation to continue for:', to.path);
      return;
    }
    
    // If user is not authenticated, simply save the redirect path
    // Let the client-side router guard handle the actual navigation cancellation
    if (!isAuthenticated.value) {
      console.log('Not authenticated for protected route:', to.path);
      
      // Store the intended destination for after login
      const authRedirectPath = useState<string | null>('authRedirectPath', () => null);
      authRedirectPath.value = to.fullPath;
      
      // No need to abort or redirect here, let the client plugin handle it
      // This avoids double navigation and timer conflicts
    }
  }
}); 