<template>
  <div class="app-container" :class="{ 'dark': $colorMode.value === 'dark' }">
    <NuxtLayout>
      <NuxtErrorBoundary @error="handleError">
        <NuxtPage />
        <template #error="{ error }">
          <ClientOnly>
            <div class="p-4 bg-red-50 border border-red-100 rounded-lg">
              <div v-if="isDev" class="error-details">
                <p>{{ error }}</p>
                <button class="btn btn-primary mt-4" @click="clearError(error)">
                  Clear Error
                </button>
              </div>
              <div v-else>
                <p>An error occurred. Please try again.</p>
                <button class="btn btn-primary mt-4" @click="clearError(error)">
                  Try Again
                </button>
              </div>
            </div>
          </ClientOnly>
        </template>
      </NuxtErrorBoundary>
    </NuxtLayout>
  </div>
</template>

<script setup>
// Access the color mode
const colorMode = useColorMode()
import { ref, computed } from 'vue'
import { isClient, safeWindow } from '~/utils/clientSide'

// Set the initial color mode based on system preference
const isHydrating = ref(true)
const config = useRuntimeConfig()
const isDev = computed(() => config.public.nodeEnv === 'development')

// Add proper viewport meta tag
useHead({
  meta: [
    { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=5' }
  ]
})

onMounted(() => {
  const nuxtApp = useNuxtApp()
  
  if (nuxtApp.isClient) {
    const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    if (systemPrefersDark) {
      colorMode.preference = 'dark'
    }
    
    // Check if Auth0 is configured
    if (!config.public.auth0Domain || !config.public.auth0ClientId) {
      console.warn('Auth0 configuration missing. Please set AUTH0_DOMAIN and AUTH0_CLIENT_ID in your .env file.')
    }
  }

  setTimeout(() => {
    isHydrating.value = false
  }, 100)
})

const handleError = (error) => {
  console.error('Application error:', error)
}

const clearError = (error) => {
  if (error) {
    error.value = null
  }
  
  if (isClient()) {
    window.location.reload()
  }
}
</script>

<style>
:root {
  --color-primary: #0077cc;
  --color-primary-dark: #005fa3;
  --color-primary-rgb: 0, 119, 204;
  --color-secondary: #4a5568;
  --color-accent: #38b2ac;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-background-alt: #f7fafc;
  --color-text: #2d3748;
  --color-muted: #718096;
  --color-border: #e2e8f0;
  --font-sans: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  --font-mono: 'JetBrains Mono', Monaco, Consolas, 'Courier New', monospace;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.dark {
  --color-primary: #63b3ed;
  --color-primary-dark: #4299e1;
  --color-primary-rgb: 99, 179, 237;
  --color-secondary: #a0aec0;
  --color-accent: #4fd1c5;
  --color-background: #1a202c;
  --color-background-rgb: 26, 32, 44;
  --color-background-alt: #2d3748;
  --color-text: #f7fafc;
  --color-muted: #a0aec0;
  --color-border: #2d3748;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: var(--font-sans);
  background-color: var(--color-background);
  color: var(--color-text);
  transition: background-color 0.3s ease, color 0.3s ease;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.app-container {
  min-height: 100vh;
  width: 100%;
}
</style>
