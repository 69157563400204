import { createAuth0 } from '@auth0/auth0-vue';

export default defineNuxtPlugin((nuxtApp) => {
  // Only run on client-side
  if (typeof window === 'undefined') {
    console.log('Auth0 plugin: Skipping initialization on server side');
    return;
  }
  
  try {
    const config = useRuntimeConfig();
    
    // Check if Auth0 configuration is available
    if (!config.public.auth0Domain || !config.public.auth0ClientId) {
      console.warn('Auth0 configuration is missing. Authentication will not work properly.');
      return;
    }
    
    // Get the current origin for redirect URI
    const origin = window.location.origin;
    const redirectUri = `${origin}/auth/callback`;
    
    console.log('Auth0 Plugin: Initializing with domain:', config.public.auth0Domain);
    
    // Enhanced configuration with better state handling
    const auth0 = createAuth0({
      domain: config.public.auth0Domain,
      clientId: config.public.auth0ClientId,
      authorizationParams: {
        redirect_uri: redirectUri,
        audience: config.public.auth0Audience,
      },
      cacheLocation: 'localstorage' as const,
      useRefreshTokens: true
    });

    // Make auth0 available to Vue components
    nuxtApp.vueApp.use(auth0);
    nuxtApp.provide('auth', auth0);
    
    // Handle redirect callback when coming back from Auth0
    nuxtApp.hook('app:created', async () => {
      // Only process if we're on the callback path
      if (window.location.pathname === '/auth/callback') {
        const router = useRouter();
        console.log('Auth0: Detected callback path');
        
        // Check for Auth0 search parameters
        const searchParams = new URLSearchParams(window.location.search);
        const hasCode = searchParams.has('code');
        const hasError = searchParams.has('error');
        
        if (!hasCode && !hasError) {
          console.log('Auth0: No auth parameters found, redirecting to home');
          router.replace('/');
          return;
        }
        
        // Handle Auth0 errors explicitly
        if (hasError) {
          const error = searchParams.get('error');
          const errorDescription = searchParams.get('error_description');
          console.error('Auth0 login error:', error, errorDescription);
          router.replace('/');
          return;
        }
        
        // Try to process the callback
        try {
          console.log('Auth0: Processing redirect callback');
          
          // Manual callback handling with better error tolerance
          const { appState } = await auth0.handleRedirectCallback(window.location.href);
          
          // Wait a moment for Auth0 to complete its internal processing
          setTimeout(() => {
            // Navigate to the target URL or home
            const targetUrl = (appState && appState.targetUrl) ? appState.targetUrl : '/';
            console.log('Auth0: Login successful, redirecting to', targetUrl);
            router.replace(targetUrl);
          }, 150);
        } catch (error: any) {
          console.error('Error handling Auth0 callback:', error);
          
          // Handle invalid state errors more gracefully
          if (error.message && error.message.includes('Invalid state')) {
            console.log('Auth0: State mismatch detected, attempting recovery');
            
            try {
              // Try to complete authentication even with state mismatch
              await auth0.checkSession();
              console.log('Auth0: Successfully recovered session');
              // If we got here, authentication succeeded despite the state error
              router.replace('/');
            } catch (sessionError) {
              console.error('Auth0: Could not recover session after state mismatch:', sessionError);
              // Force a clean reload to reset all Auth0 state
              window.location.href = '/';
            }
            return;
          }
          
          // For other errors, just go home
          router.replace('/');
        }
      }
    });
    
    console.log('Auth0 plugin initialized successfully');
    
    return {
      provide: {
        auth0
      }
    };
  } catch (error) {
    console.error('Failed to initialize Auth0:', error);
    return {};
  }
}); 