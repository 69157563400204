export default defineNuxtPlugin(() => {
  // This plugin adds safety checks for DOM manipulations
  if (typeof window !== 'undefined') {
    // Safe remove method that checks if element exists and has a parent
    const originalRemove = Element.prototype.remove
    Element.prototype.remove = function() {
      try {
        if (this && this.parentNode) {
          originalRemove.call(this)
        } else {
          console.warn('DOM Safety: Attempted to remove element with no parent')
        }
      } catch (error) {
        console.warn('Failed to remove element safely:', error)
      }
    }
    
    // Patch Element.appendChild method
    const originalAppendChild = Node.prototype.appendChild
    Node.prototype.appendChild = function<T extends Node>(child: T): T {
      try {
        if (child && this) {
          return originalAppendChild.call(this, child) as T
        } else {
          console.warn('DOM Safety: Invalid appendChild operation')
          return child
        }
      } catch (error) {
        console.warn('Failed to appendChild safely:', error)
        return child
      }
    }
    
    // Safe access to parentNode property
    const parentNodeDescriptor = Object.getOwnPropertyDescriptor(Node.prototype, 'parentNode')
    if (parentNodeDescriptor && parentNodeDescriptor.get) {
      const originalGetParentNode = parentNodeDescriptor.get
      Object.defineProperty(Node.prototype, 'parentNode', {
        get: function() {
          try {
            return originalGetParentNode.call(this)
          } catch (error) {
            console.warn('DOM Safety: Failed to access parentNode safely:', error)
            return null
          }
        },
        configurable: true
      })
    }
    
    console.log('DOM safety plugin initialized')
  }
}) 