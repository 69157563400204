<template>
  <div class="app-container" :class="{ 'dark': $colorMode.value === 'dark' }">
    <div class="min-h-screen w-full flex items-center justify-center p-4 py-12">
      <div class="error-container mx-auto">
        <!-- Error Icon -->
        <div class="error-icon-wrapper">
          <ClientOnly>
            <!-- 404 Not Found - Custom Frowny Face -->
            <div v-if="error?.statusCode === 404" class="sad-face-container">
              <div class="sad-face">
                <div class="face-circle">
                  <div class="eyes">
                    <div class="eye"></div>
                    <div class="eye"></div>
                  </div>
                  <div class="mouth"></div>
                </div>
              </div>
              <div class="error-badge">404</div>
            </div>
            
            <!-- 403 Forbidden -->
            <div v-else-if="error?.statusCode === 403" class="forbidden-icon-container">
              <div class="forbidden-icon">
                <UIcon name="i-heroicons-lock-closed" class="icon" />
              </div>
              <div class="error-badge">403</div>
            </div>
            
            <!-- 401 Unauthorized -->
            <div v-else-if="error?.statusCode === 401" class="unauthorized-icon-container">
              <div class="unauthorized-icon">
                <UIcon name="i-heroicons-shield-exclamation" class="icon" />
              </div>
              <div class="error-badge">401</div>
            </div>
            
            <!-- 500 Server Error -->
            <div v-else-if="error?.statusCode >= 500" class="server-error-icon-container">
              <div class="server-error-icon">
                <UIcon name="i-heroicons-server" class="icon" />
              </div>
              <div class="error-badge">{{ error?.statusCode }}</div>
            </div>
            
            <!-- Other Errors -->
            <div v-else class="generic-error-icon-container">
              <div class="generic-error-icon">
                <UIcon name="i-heroicons-exclamation-triangle" class="icon" />
              </div>
              <div class="error-badge">{{ error?.statusCode || '?' }}</div>
            </div>
            
            <template #fallback>
              <div class="fallback-icon">
                <div class="error-badge">{{ error?.statusCode || '?' }}</div>
              </div>
            </template>
          </ClientOnly>
        </div>
        
        <!-- Error Content -->
        <div class="error-content">
          <h1 class="error-title">{{ errorTitle }}</h1>
          <p class="error-message">{{ errorMessage }}</p>
          
          <!-- Suggestions -->
          <div v-if="suggestions.length > 0" class="suggestions-container">
            <h2 class="suggestions-title">Try these suggestions:</h2>
            <ul class="suggestions-list">
              <li v-for="(suggestion, index) in suggestions" :key="index" class="suggestion-item">
                {{ suggestion }}
              </li>
            </ul>
          </div>
          
          <!-- Error Details (collapsible) -->
          <UCollapseTransition>
            <div v-if="showErrorDetails" class="error-details-container">
              <pre class="error-details-content">{{ errorDetails }}</pre>
            </div>
          </UCollapseTransition>
          
          <!-- Action Buttons -->
          <div class="action-buttons">
            <button 
              @click="handleError" 
              class="action-button details-button"
              :class="{ 'active': showErrorDetails }"
            >
              <ClientOnly>
                <UIcon :name="showErrorDetails ? 'i-heroicons-eye-slash' : 'i-heroicons-eye'" class="button-icon" />
                <template #fallback>
                  <span>{{ showErrorDetails ? 'Hide Details' : 'Show Details' }}</span>
                </template>
              </ClientOnly>
              <span>{{ showErrorDetails ? 'Hide Details' : 'Show Details' }}</span>
            </button>
            
            <button 
              @click="navigateToHome" 
              class="action-button home-button"
            >
              <ClientOnly>
                <UIcon name="i-heroicons-home" class="button-icon" />
                <template #fallback>
                  <span>🏠</span>
                </template>
              </ClientOnly>
              <span>Back to Home</span>
            </button>
            
            <button 
              v-if="canRetry" 
              @click="retryNavigation" 
              class="action-button retry-button"
            >
              <ClientOnly>
                <UIcon name="i-heroicons-arrow-path" class="button-icon" />
                <template #fallback>
                  <span>🔄</span>
                </template>
              </ClientOnly>
              <span>Try Again</span>
            </button>
          </div>
          
          <!-- Support Link -->
          <div class="support-link-container">
            <ClientOnly>
              <UIcon name="i-heroicons-life-buoy" class="support-icon" />
              <template #fallback>
                <span>🔍</span>
              </template>
            </ClientOnly>
            <span>Need help?</span>
            <a href="/contact" class="support-link">Contact Support</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Access the color mode
import { isClient } from '~/utils/clientSide'

const colorMode = useColorMode()
const props = defineProps({
  error: Object
})

// Ensure colorMode changes are applied to the UI
watch(() => colorMode.value, (newValue) => {
  if (isClient()) {
    document.documentElement.classList.toggle('dark', newValue === 'dark')
  }
}, { immediate: true })

const nuxtApp = useNuxtApp()
const route = useRoute()
const showErrorDetails = ref(false)
const canRetry = computed(() => props.error?.statusCode !== 404)

// Dynamic error title based on status code
const errorTitle = computed(() => {
  if (!props.error?.statusCode) return 'An Error Occurred'
  
  switch (props.error.statusCode) {
    case 404: return 'Page Not Found'
    case 403: return 'Access Forbidden'
    case 401: return 'Authentication Required'
    case 500: return 'Server Error'
    case 503: return 'Service Unavailable'
    default: return `Error ${props.error.statusCode}`
  }
})

// Dynamic error message based on status code
const errorMessage = computed(() => {
  if (props.error?.message) return props.error.message
  
  if (!props.error?.statusCode) return 'Sorry, we encountered an unexpected error.'
  
  switch (props.error.statusCode) {
    case 404: return 'The page you are looking for does not exist or has been moved.'
    case 403: return 'You do not have permission to access this resource.'
    case 401: return 'Please login to access this page.'
    case 500: return 'Our server encountered an error. Please try again later.'
    case 503: return 'The service is temporarily unavailable. Please try again later.'
    default: return 'Something went wrong. Please try again or contact support.'
  }
})

// Helpful suggestions based on error type
const suggestions = computed(() => {
  if (!props.error?.statusCode) return []
  
  switch (props.error.statusCode) {
    case 404:
      return [
        'Check the URL for spelling errors.',
        'The page might have been moved or deleted.',
        'Return to the previous page or go to the home page.',
        'Use the search function to find what you\'re looking for.'
      ]
    case 403:
      return [
        'Check if you are logged in with the correct account.',
        'You might need higher permissions to access this resource.',
        'Contact your administrator if you believe you should have access.'
      ]
    case 401:
      return [
        'Sign in to your account to access this page.',
        'Your session may have expired. Try logging in again.',
        'Check if you are using the correct credentials.'
      ]
    case 500:
    case 503:
      return [
        'Try refreshing the page.',
        'Try again in a few minutes.',
        'Clear your browser cache and cookies.',
        'Contact support if the problem persists.'
      ]
    default:
      return [
        'Try refreshing the page.',
        'Check your internet connection.',
        'Contact support if the problem persists.'
      ]
  }
})

// Better error details formatting
const errorDetails = computed(() => {
  if (!props.error) return 'No error details available'
  
  try {
    const errorData = {
      message: props.error.message || 'No message available',
      statusCode: props.error.statusCode,
      path: props.error.url || (nuxtApp.isClient ? window?.location?.pathname : null) || 'Unknown path',
      stack: (props.error.stack || 'No stack trace available')
        .split('\n')
        .filter((line: string) => line.trim())
        .slice(0, 8)
        .join('\n'),
      data: props.error.data
    };
    
    return JSON.stringify(errorData, null, 2);
  } catch (e) {
    return `${props.error.message || 'Unknown error'} (Status: ${props.error.statusCode || 'unknown'})`
  }
})

// Log the error to the console in production
onMounted(() => {
  const config = useRuntimeConfig()
  if (config.public.nodeEnv === 'production') {
    console.error('[Error Page]', props.error)
  }
})

function handleError() {
  showErrorDetails.value = !showErrorDetails.value
  if (!showErrorDetails.value && props.error?.statusCode !== 404) {
    clearError({ redirect: route.path || '/' })
  }
}

function navigateToHome() {
  clearError({ redirect: '/' })
}

function retryNavigation() {
  clearError({ redirect: route.path || '/' })
}
</script>

<style scoped>
/* Use CSS variables from app.vue */
.app-container {
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-background);
  color: var(--color-text);
  font-family: var(--font-sans);
}

/* Error container */
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 480px;
  width: 100%;
  padding: 2rem;
  margin: 0 auto;
  margin-top: 100px;
  background-color: var(--color-background);
  border-radius: 1rem;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--color-border);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

/* Error icon wrapper */
.error-icon-wrapper {
  position: relative;
  width: 128px;
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

/* Error badge */
.error-badge {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--color-background-alt);
  color: var(--color-muted);
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Common icon styles */
.icon {
  width: 100%;
  height: 100%;
}

/* Sad face specific styling */
.sad-face-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: float 4s ease-in-out infinite;
}

.sad-face {
  width: 100%;
  height: 100%;
  position: relative;
}

.face-circle {
  width: 100%;
  height: 100%;
  background-color: var(--color-primary);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 15px -3px rgba(var(--color-primary-rgb), 0.3), 0 4px 6px -4px rgba(var(--color-primary-rgb), 0.2);
}

.eyes {
  display: flex;
  justify-content: space-around;
  width: 65%;
  margin-bottom: 15px;
}

.eye {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  position: relative;
}

.mouth {
  width: 50px;
  height: 25px;
  border: 4px solid white;
  border-radius: 0 0 30px 30px;
  border-top: 0;
  transform: rotate(180deg);
}

/* Forbidden icon */
.forbidden-icon-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f59e0b; /* amber-500 */
  animation: shake 5s cubic-bezier(.36,.07,.19,.97) infinite;
}

/* Unauthorized icon */
.unauthorized-icon-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f97316; /* orange-500 */
  animation: pulse 2.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

/* Server error icon */
.server-error-icon-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ef4444; /* red-500 */
  animation: spin 8s linear infinite;
}

/* Generic error icon */
.generic-error-icon-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8b5cf6; /* purple-500 */
  animation: wobble 3.5s ease-in-out infinite;
}

/* Error content */
.error-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-title {
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
  text-align: center;
  color: var(--color-text);
}

.error-message {
  font-size: 1.125rem;
  line-height: 1.6;
  text-align: center;
  color: var(--color-muted);
  margin-bottom: 1.5rem;
}

/* Suggestions */
.suggestions-container {
  width: 100%;
  background-color: var(--color-background-alt);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.suggestions-title {
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--color-text);
}

.suggestions-list {
  list-style-type: disc;
  padding-left: 1.5rem;
  color: var(--color-muted);
}

.suggestion-item {
  margin-bottom: 0.25rem;
}

/* Error details */
.error-details-container {
  width: 100%;
  background-color: var(--color-background-alt);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border: 1px solid var(--color-border);
  max-height: 300px;
  overflow-y: auto;
}

.error-details-content {
  font-family: var(--font-mono);
  font-size: 0.875rem;
  color: var(--color-muted);
  white-space: pre-wrap;
  line-height: 1.5;
}

/* Action buttons */
.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  margin-bottom: 1.5rem;
}

@media (min-width: 640px) {
  .action-buttons {
    flex-direction: row;
  }
}

.action-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
}

.details-button {
  background-color: transparent;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.details-button.active {
  background-color: var(--color-primary);
  color: white;
}

.details-button:hover {
  background-color: rgba(var(--color-primary-rgb), 0.1);
}

.details-button.active:hover {
  background-color: var(--color-primary-dark);
}

.home-button {
  background-color: var(--color-background-alt);
  color: var(--color-text);
  border: 1px solid var(--color-border);
}

.home-button:hover {
  background-color: var(--color-border);
}

.retry-button {
  background-color: #10b981; /* emerald-500 */
  color: white;
  border: none;
}

.retry-button:hover {
  background-color: #059669; /* emerald-600 */
}

.button-icon {
  width: 1.25rem;
  height: 1.25rem;
  transition: transform 0.2s ease;
}

.action-button:hover .button-icon {
  transform: scale(1.1);
}

.retry-button:hover .button-icon {
  transform: rotate(45deg);
}

/* Support link */
.support-link-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  border-top: 1px solid var(--color-border);
  margin-top: 1rem;
  width: 100%;
  color: var(--color-muted);
}

.support-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.support-link {
  color: var(--color-primary);
  font-weight: 500;
  transition: color 0.2s ease;
}

.support-link:hover {
  color: var(--color-primary-dark);
  text-decoration: underline;
}

/* Animations */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  2%, 6%, 10% {
    transform: translateX(-2px);
  }
  4%, 8%, 12% {
    transform: translateX(2px);
  }
  14% {
    transform: translateX(0);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(0.95);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes wobble {
  0%, 100% {
    transform: translateX(0%);
  }
  15% {
    transform: translateX(-2%) rotate(-1deg);
  }
  30% {
    transform: translateX(1.5%) rotate(1deg);
  }
  45% {
    transform: translateX(-1%) rotate(-0.5deg);
  }
  60% {
    transform: translateX(0.5%) rotate(0.25deg);
  }
  75% {
    transform: translateX(-0.25%) rotate(-0.125deg);
  }
}
</style> 