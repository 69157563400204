import { defineNuxtPlugin } from '#app'

/**
 * Plugin to handle Nuxt Icon errors gracefully
 */
export default defineNuxtPlugin((nuxtApp) => {
  // Override console errors for icon loading to make them less noisy
  const originalConsoleError = console.error
  console.error = (...args) => {
    // Filter out icon loading errors from the console
    const errorMsg = args[0]?.toString() || ''
    if (errorMsg.includes('[Icon]') && errorMsg.includes('failed to load icon')) {
      console.warn('[Icon] Failed to load an icon. Using fallback.')
      return
    }
    
    // Let other errors pass through normally
    originalConsoleError(...args)
  }
  
  // Custom error handling for icon component
  nuxtApp.vueApp.config.errorHandler = (err: unknown, vm, info) => {
    // Check if the error is related to icons
    const errorMessage = err instanceof Error ? err.message : String(err)
    if (errorMessage.includes('icon') || (typeof info === 'string' && info.includes('icon'))) {
      console.warn('[Vue] Handled icon loading error:', errorMessage)
      return
    }
    
    // Default error handling for other errors
    console.error(err)
  }
}) 