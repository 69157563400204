/**
 * Client-side plugin for route protection with Vue Router navigation guards
 */
export default defineNuxtPlugin(nuxtApp => {
  // Get the router instance
  const router = useRouter();
  
  // List of routes that require authentication
  const protectedRoutes = ['/blog/new', '/profile'];
  
  // Add a global navigation guard
  router.beforeEach((to, from, next) => {
    // Check if the route requires authentication
    const requiresAuth = protectedRoutes.some(route => to.path.startsWith(route));
    
    if (requiresAuth) {
      console.log('Client: Navigation to protected route:', to.path);
      
      // Get auth state from our composable
      const { isAuthenticated, isLoading } = useAuth();
      
      // If still loading auth state, allow navigation and let the component handle it
      if (isLoading.value) {
        console.log('Client: Auth still loading, proceeding to', to.path);
        return next();
      }
      
      // If not authenticated, cancel navigation and save the path for later
      if (!isAuthenticated.value) {
        console.log('Client: Not authenticated for', to.path);
        
        // Save the intended destination
        const authRedirectPath = useState<string | null>('authRedirectPath', () => null);
        authRedirectPath.value = to.fullPath;
        
        // Silently cancel this navigation instead of triggering a new one
        return next(false);
      }
    }
    
    // For non-protected routes or authenticated users, proceed
    return next();
  });
}); 