import MarkdownIt from 'markdown-it';
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  // Initialize markdown-it with desired options
  const md = new MarkdownIt({
    html: true,        // Enable HTML tags in source
    xhtmlOut: true,    // Use '/' to close single tags (<br />)
    breaks: true,      // Convert '\n' in paragraphs into <br>
    linkify: true,     // Autoconvert URL-like text to links
    typographer: true, // Enable smartypants and other sweet transforms
  });

  // Optional: Add any plugins you want to use
  // Example: md.use(require('markdown-it-anchor'));
  
  // Provide markdown-it to the app
  nuxtApp.provide('mdit', md);
}); 