// Read the version from package.json
import { defineNuxtPlugin } from '#app'
import pkg from '../package.json'

export default defineNuxtPlugin(() => {
  const version = pkg.version || '0.0.0'

  // Make version available to the app
  return {
    provide: {
      appVersion: version
    }
  }
}) 