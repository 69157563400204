/**
 * Utility functions for client-side operations
 */
import { useNuxtApp } from '#app'

/**
 * Checks if the code is running on the client-side
 * @returns boolean indicating if current environment is client-side
 */
export function isClient(): boolean {
  // First, most reliable check - window object exists
  if (typeof window !== 'undefined') {
    return true
  }
  
  // Secondary check - try using Nuxt's isClient property
  try {
    const nuxtApp = useNuxtApp()
    return Boolean(nuxtApp.isClient)
  } catch (e) {
    // Cannot access nuxtApp, likely in a non-Nuxt context
  }
  
  // Final fallback - check for document
  return typeof document !== 'undefined'
}

/**
 * Safely executes a function only if on the client-side
 * @param fn Function to execute on client-side
 * @param fallbackValue Optional fallback value to return on server-side
 * @returns Result of the function or fallback value
 */
export function runOnClient<T>(fn: () => T, fallbackValue?: T): T | undefined {
  if (isClient()) {
    return fn()
  }
  return fallbackValue
}

/**
 * Safely access window properties only on the client side
 * @param accessor Function that accesses window properties
 * @param fallbackValue Optional fallback value to return on server-side
 * @returns The window property value or fallback value
 */
export function safeWindow<T>(accessor: () => T, fallbackValue?: T): T | undefined {
  return runOnClient(accessor, fallbackValue)
}

/**
 * Safely access document properties only on the client side
 * @param accessor Function that accesses document properties
 * @param fallbackValue Optional fallback value to return on server-side
 * @returns The document property value or fallback value
 */
export function safeDocument<T>(accessor: () => T, fallbackValue?: T): T | undefined {
  return runOnClient(accessor, fallbackValue)
}

/**
 * Safely access localStorage only on the client side
 * @param key localStorage key
 * @param fallbackValue Optional fallback value
 * @returns The localStorage value or fallback
 */
export function safeLocalStorage(key: string, fallbackValue?: string): string | undefined {
  return runOnClient(() => localStorage.getItem(key) || fallbackValue, fallbackValue)
}

/**
 * Safely set localStorage only on the client side
 * @param key localStorage key
 * @param value Value to store
 * @returns boolean indicating success
 */
export function setLocalStorage(key: string, value: string): boolean {
  return !!runOnClient(() => {
    localStorage.setItem(key, value)
    return true
  }, false)
} 