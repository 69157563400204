import { defineNuxtPlugin } from "#app";
import VueEasymde from "vue3-easymde";
import "easymde/dist/easymde.min.css";
// Font Awesome will be loaded in the head instead of imported directly

export default defineNuxtPlugin((nuxtApp) => {
  // Add Font Awesome CSS to the head
  nuxtApp.vueApp.use(VueEasymde);

  // Properly add Font Awesome CSS to the document head
  if (nuxtApp.isClient) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css';
    document.head.appendChild(link);
  }
});